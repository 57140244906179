<template>
  <div>
    <a-modal
      :visible="visible"
      :title="addFlag ? '新增' : '编辑'"
      @cancel="cancelHandler"
      @ok="okHandler"
      width="800px"
    >
      <a-form-model ref="formState" :model="formState" :rules="rules">
        <a-row>
          <a-col :span="24" v-if="addFlag">
            <a-form-model-item label="中控型号" prop="model" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <a-select
                show-search
                v-model="formState.model"
                placeholder="请选择型号"
                :disabled="!addFlag"
                @change="modelChange"
              >
                <a-select-option v-for="model in modelList" :key="model.model" :value="model.model">{{
                  model.model
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <template v-else>
            <a-col :span="12">
              <a-form-model-item label="中控型号" prop="model" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
                <a-select
                  show-search
                  v-model="formState.model"
                  placeholder="请选择型号"
                  :disabled="!addFlag"
                  @change="modelChange"
                >
                  <a-select-option v-for="model in modelList" :key="model.model" :value="model.model">{{
                    model.model
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="最后修改时间"
                prop="lastModificationTime"
                :labelCol="{ span: 8 }"
                :wrapperCol="{ span: 16 }"
              >
                <a-input :disabled="!addFlag" :value="formState.lastModificationTime | parseTime | orNA" />
              </a-form-model-item>
            </a-col>
          </template>

          <a-col :span="12">
            <a-form-model-item
              label="中控硬件版本"
              prop="hardVersion"
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 16 }"
            >
              <a-select
                show-search
                v-model="formState.hardVersion"
                :placeholder="this.formState.model ? '请选择硬件版本' : '请先选择中控型号'"
                :disabled="!addFlag"
              >
                <a-select-option
                  v-for="hardVersion in hardVersionList"
                  :key="hardVersion.hardVersion"
                  :value="hardVersion.hardVersion"
                  >{{ hardVersion.hardVersion }}</a-select-option
                >
              </a-select>
            </a-form-model-item></a-col
          >
          <a-col :span="12">
            <a-form-model-item
              label="中控软件版本"
              prop="softVersion"
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 16 }"
            >
              <a-select
                show-search
                v-model="formState.softVersion"
                :placeholder="this.formState.model ? '请选择软件版本' : '请先选择中控型号'"
                :disabled="!addFlag"
              >
                <a-select-option
                  v-for="softVersion in softVersionList"
                  :key="softVersion.softVersion"
                  :value="softVersion.softVersion"
                  >{{ softVersion.softVersion }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              label="中控功能列表"
              prop="features"
              v-show="featureShow"
              :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 24 }"
            >
              <a-transfer
                style="margin-left: 25px"
                show-search
                :data-source="featureList"
                :filter-option="filterOption"
                :target-keys="formState.features"
                :render="(item) => item.title"
                @change="handleChange"
                :list-style="{
                  width: '343px',
                  height: '400px',
                }"
              ></a-transfer>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { Modal } from 'ant-design-vue';

export default {
  name: 'CpuRelationAddOrEditModal',
  props: ['visible', 'item'],
  data() {
    return {
      formState: this.item || {
        id: undefined,
        model: undefined,
        hardVersion: undefined,
        softVersion: undefined,
        features: [],
      },
      featureList: [],
      modelList: [],
      softVersionList: [],
      hardVersionList: [],
      rules: {
        model: [{ required: true, message: '中控型号 不能为空', trigger: 'change' }],
        hardVersion: [{ required: true, message: '中控硬件版本 不能为空', trigger: 'change' }],
        softVersion: [{ required: true, message: '中控软件版本 不能为空', trigger: 'change' }],
      },
      featureShow: true,
    };
  },
  methods: {
    cancelHandler() {
      this.$emit('update:visible', false);
    },
    async okHandler() {
      this.$refs.formState.validate(async (relsValid) => {
        if (relsValid) {
          if (this.addFlag) {
            await this.$apiManager.cpuRelation.addCpuRelation(this.formState);
          } else {
            await this.$apiManager.cpuRelation.updateCpuRelation(this.formState);
          }
          this.$message.success(this.addFlag ? '添加成功' : '修改成功');
          this.$emit('updateView');
          this.$emit('update:visible', false);
        }
      });
    },
    filterOption(inputValue, option) {
      return option.title.indexOf(inputValue) > -1;
    },
    handleChange(keys) {
      this.formState.features = keys;
    },

    /** 型号选择发生改变,根据型号获取软硬件版本 */
    async modelChange(model) {
      this.$set(this.formState, 'hardVersion', undefined);
      this.$set(this.formState, 'softVersion', undefined);
      const hardVersionRes = await this.$apiManager.cpuHardware.getCpuHardwareList({ model, unpaged: true });
      this.hardVersionList = hardVersionRes.data.elements;
      const softVersionRes = await this.$apiManager.cpuSoftware.getCpuSoftwareList({ model, unpaged: true });
      this.softVersionList = softVersionRes.data.elements;
    },
    /** 获取型号与功能列表 */
    async getModelList() {
      const modelRes = await this.$apiManager.cpuModel.getCpuModelList({ unpaged: true });
      this.modelList = modelRes.data.elements;
    },
    async getFunctionList() {
      const functionRes = await this.$apiManager.cpuFunction.getCpuFunctionList({ unpaged: true });
      const functionList = functionRes.data.elements;
      this.featureList = functionList.map((func) => ({
        key: func.feature,
        title: func.feature,
      }));
    },
    showConfirm(res) {
      const that = this;
      Modal.confirm({
        title: () => '提示',
        content: () => '该关联已存在，是否进入编辑？',
        okText: '进入编辑',
        cancelText: '返回修改',
        onOk() {
          // 进入当前关联编辑状态
          that.addFlag = res;
        },
        onCancel() {
          // 将当前功能列表隐藏
          that.featureShow = false;
        },
      });
    },
  },
  mounted() {
    if (this.addFlag) {
      this.getModelList();
      this.getFunctionList();
    } else {
      this.getFunctionList();
    }
  },
  computed: {
    addFlag: {
      get() {
        return this.formState.id === undefined;
      },
      set(value) {
        this.$set(this.formState, 'features', value.data.features);
        this.$set(this.formState, 'id', value.data.id);
      },
    },
    triplet() {
      const { model, hardVersion, softVersion } = this.formState;
      return {
        model,
        hardVersion,
        softVersion,
      };
    },
  },
  watch: {
    triplet(newValue) {
      this.featureShow = true;
      if (newValue.model && newValue.hardVersion && newValue.softVersion) {
        this.$apiManager.cpuRelation.getCpuRelation(newValue).then((res) => {
          // 说明当前关联已存在,显示提示弹窗
          if (res.data) {
            this.showConfirm(res);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
